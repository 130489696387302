import React, { useState } from 'react';
import './Envelope.css';
import '../App.css';

const Envelope = ({ onOpen }) => {
  const [isOpened, setIsOpened] = useState(false);

  const handleClick = () => {
    setIsOpened(true);
    setTimeout(() => {
      onOpen();
    }, 600); // Duration of the animation
  };

  return (
    <div className={`envelope ${isOpened ? 'opened' : ''}`} onClick={handleClick}>
      <h2 className="envelope-text">Nathaly Gudiel</h2>
      <h2 className="envelope-text2">XV - Años</h2>
      <div className="envelope-top"></div>
      <div className="envelope-bottom"></div>
    </div>
  );
};

export default Envelope;
