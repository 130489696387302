import React from 'react'
import '../App.css';

export default function Header({name, eventName}) {
    return (
        <header className="header">
            <div className="header-text">
                <h1 className='fade-in-text'>{name}</h1>
                <h1 className='fade-in-text big-text2'>{eventName}</h1>
            </div>
        </header>
    )
}
