import React from 'react'
import './Modal.css'
import '../App.css'

const Modal = ({ show, onClose, message }) => {
    if (!show) {
      return null;
    }
  
    return (
      <div className="modal-overlay">
        <div className="modal">
{/*           <button className="close-button" onClick={onClose}>
            &times;
          </button> */}
          <div className="modal-content big-text">
            <p>{message}</p>
            <button className="button" onClick={onClose}>
              Aceptar
            </button>
          </div>
        </div>
      </div>
    );
}

export default Modal;
