import '../App.css';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Section from './Section';
import Modal from './Modal';
import MusicPlayer from './MusicPlayer';
import Envelope from './Envelope';

function Page() {
    const { name } = useParams();
    const [invited, setInvited] = useState('Invitación no válida');
    const [invitedAmount, setInvitedAmount] = useState('N/A');

    const targetDate = new Date('2024-08-31T18:00:00');

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const [index, setIndex] = useState(-1);

    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState('');

    const [isEnvelopeOpened, setIsEnvelopeOpened] = useState(false);

    const handleEnvelopeOpen = () => {
        setIsEnvelopeOpened(true);
    };


    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const sendConfirmation = (willAssist) => {
        console.log(index);
        console.log(invitedAmount);
        axios.post('https://norse-lens-274518.uc.r.appspot.com/api/google-sheets/write', { "index": index, "values": [[willAssist ? 'Si' : 'No', willAssist ? invitedAmount : 0, 0]] })
            .then(response => {
                const data = response.data;
                console.log(data);
                setMessage(willAssist ? '¡Gracias por confirmar! ¡Esperamos tu asistencia!' : '¡Gracias por avisar que no podras asistir!');
                toggleModal();
            })
            .catch(error => {
                console.error('Error al enviar la confirmación:', error);
            });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const timeLeft = targetDate - now;

            if (timeLeft < 0) {
                clearInterval(interval);
                setDays(0);
                setHours(0);
                setMinutes(0);
                setSeconds(0);
            } else {
                const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
                const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

                setDays(days);
                setHours(hours);
                setMinutes(minutes);
                setSeconds(seconds);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [targetDate]);

    useEffect(() => {
        document.title = `Nathaly Gudiel - ${name}`;
        axios.post('https://norse-lens-274518.uc.r.appspot.com/api/google-sheets/search', { "search": name })
            .then(response => {
                const data = response.data;
                setInvited(data.values[1]);
                setInvitedAmount(data.values[2]);
                setIndex(data.index);
            })
            .catch(error => {
                console.error('Error al obtener la invitación:', error);
            });
    }, [name]);

    const handleWazeButtonClick = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        let url;
        console.log(userAgent);
        if (/android/i.test(userAgent)) {
          // Dispositivo Android
          url = 'waze://?ll=14.587614453709575,-90.52069117298873&navigate=yes';
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          // Dispositivo iOS
          url = 'waze://?ll=14.587614453709575,-90.52069117298873&navigate=yes';
        } else {
          // PC o navegador web
          url = 'https://www.waze.com/en/live-map/directions/hotel-las-americas-avenida-las-americas-9-08-zona-13,-guatemala?place=w.176619666.1766065587.447749';
        }

        window.open(url, '_blank');
    };

    return (
        <div className="App">

            {!isEnvelopeOpened && <Envelope onOpen={handleEnvelopeOpen} />}
            {isEnvelopeOpened && (
                <>
                    <Modal
                        show={showModal}
                        onClose={toggleModal}
                        message={message}
                    />
                    <MusicPlayer />
                    <Header name="Nathaly Gudiel" eventName="XV Años" />
                    <Section bgType="regular">
                        <p className="big-text">Con la bendición de Dios y el amor de mis padres Gustavo Gudiel y Jessica Mendoza me siento feliz de llegar a este día que dejaré atrás mi infancia y comenzaré un nuevo viaje.</p>
                    </Section>
                    <section className="header countdown-container">

                        <div className="overlay">
                            <p className="big-text2" style={{ textAlign: "center" }}>Sábado 31 de Agosto 2024</p>
                            <div className="countdown">
                                <div>
                                    <div className="number" id="days">{days}</div>
                                    <div className="label">Días</div>
                                </div>
                                <div>
                                    <div className="number" id="hours">{hours}</div>
                                    <div className="label">Horas</div>
                                </div>
                                <div>
                                    <div className="number" id="minutes">{minutes}</div>
                                    <div className="label">Min.</div>
                                </div>
                                <div>
                                    <div className="number" id="seconds">{seconds}</div>
                                    <div className="label">Seg.</div>
                                </div>
                            </div>
                            <p className="big-text2" style={{ textAlign: "center" }}>Hora: 19:00</p>
                        </div>
                    </section>
                    <Section bgType="blush">
                        <h2>¡Es un placer que nos acompañen!</h2>
                        <h1 id="invitation-name">{invited}</h1>
                    </Section>
                    <section className="section-black">
                        <h2 id="invitation-amount">
                            {/* <span className="material-symbols-outlined generic-text">
                    mail
                </span>  */}
                            Invitación válida para {invitedAmount} {parseInt(invitedAmount) > 1 ? 'personas' : 'persona'}.
                        </h2>
                    </section>
                    {/*             <section className="section">
                <div className="generic-text">Es un honor invitarlos para nosotros sus padres:</div>
                <div className="columns">
                    <div className="column big-text">Jessica Yessenia Mendoza Sanchez</div>
                    <div className="column big-text">Gustavo Adolfo Gudiel Gonzales</div>
                </div>
            </section> */}
                    <section className="header">
                        <div className="overlay">
                            <h1>Ubicación</h1>
                            <div className="generic-text">Hotel Las Americas</div>
                            <div className="generic-text">Ave. Las Américas 9-08</div>
                            <img src="https://images.trvl-media.com/lodging/18000000/17410000/17403700/17403610/b3be9f4e.jpg?impolicy=resizecrop&rw=575&rh=575&ra=fill" className="circular-image" />
                            <button className="button" style={{ marginTop: '50px' }} onClick={handleWazeButtonClick}> <i class="fa-brands fa-waze"></i> Waze </button>
                        </div>
                    </section>
                    <section className="section">
                        <h1>Código de Vestimenta</h1>
                        <div className="columns2">
                            <div className="column"><img src="/suite2.svg" className="dress" /></div>
                            <div className="column"><img src="/dress2.svg" className="dress" /></div>
                        </div>
                        <div>
                            <h2>Formal</h2>
                            <h2>Black & White</h2>
                        </div>
                    </section>
                    <section className="header">
                        <div className="overlay">
                            <h1>Regalo</h1>
                            <div className="generic-text">Mi mejor regalo es compartir contigo este día tan especial. Pero si algo me deseas obsequiar, en un sobre lo puedes depositar.</div>
                            <span className="material-symbols-outlined">
                                mail
                            </span>
                            {/* <div className="generic-text">La lluvia de sobres, es la tradición de regalar dinero en efectivo a la quinceañera en un sobre que te daré el día del evento.</div> */}
                            {/* <div className="generic-text">Gracias por tu muestra de cariño, espero verte ese día tan especial para mi.</div> */}
                        </div>
                    </section>
                    <Section bgType="regular">
                        <p className="big-text">Confirma tu asistencia antes del:</p>
                        <h1 className="heading-styled-text">15/08/2024</h1>
                        <div className="columns">
                            <div className="column">
                                <button className="button" onClick={() => sendConfirmation(true)}>Confirmar asistencia </button>
                            </div>
                            <div className="column">
                                <button className="button" onClick={() => sendConfirmation(false)}>No podre asistir</button>
                            </div>
                        </div>
                    </Section>
                    <section className="section-black">
                        <h1>Espero que me puedas acompañar.</h1>
                    </section>
                </>
            )}
        </div>
    );
}

export default Page;
