import React from 'react'
import '../App.css'

const bgTypes = {
  'regular':'section',
  'blush': 'section-blush'
}
export default function Section({bgType='regular', children}) {
  const sectionClassName = bgTypes[bgType] || bgTypes['regular']
  return (
    <section className={sectionClassName}>
        {children && <>{children}</>}
    </section>
  )
}
