import React, { useState, useEffect, useRef } from 'react';
import './MusicPlayer.css';

const MusicPlayer = () => {
    const [isMuted, setIsMuted] = useState(false);
    const audioRef = useRef(null);

    const toggleMute = () => {
        setIsMuted(!isMuted);
    };

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.muted = isMuted;
            audioRef.current.loop = true;
            audioRef.current.play();
        }
    }, [isMuted]);

    return (
        <div>
            <audio ref={audioRef} src="/fifteen.mp3"/>
            <button className="mute-button" onClick={toggleMute}>
                <span class="material-symbols-outlined">
                    {isMuted ? 'volume_off' : 'volume_up'}
                </span>

            </button>
        </div>
    );
};

export default MusicPlayer;
